import React from "react";
import Button from "reactstrap/lib/Button";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";
import TableView from "../../../../screens/Admin/Releases/components/TableView";

const INITIAL_PAGE_SIZE = 10;

const MyBalance = ({
  userEarnings,
  withdrawalAmount,
  totalWithdrawalAmount,
  pendingAmount,
  isPayoutEnabled,
  handleOpenPayoutModal,
  totalReported,
  handlePeriodClick,
  loading,
}) => {
  const customAmountCell = ({ value, row }) => {
    if (row.original.totalReported === value) {
      return (
        <div className="font-weight-bolder" style={{ color: "white" }}>
          € {value.toFixed(2)}
        </div>
      );
    } else {
      return <div className="font-weight-bolder">€ {value.toFixed(2)}</div>;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "period",

        Cell: ({ value, row }) => (
          <div
            onClick={() => handlePeriodClick(row.original.periodId)}
            className="text-primary font-weight-bolder cursor-pointer"
            title={row.original.periodId}
          >
            {value}
          </div>
        ),
      },

      {
        Header: "Total earned",
        accessor: "totalReported",
        Cell: customAmountCell,
      },
      {
        Header: "Total pending",
        accessor: "totalPending",
        Cell: customAmountCell,
      },
      {
        Header: "Total collected",
        accessor: "totalCollected",
        Cell: customAmountCell,
      },
      {
        Header: "Withdrawal",
        accessor: "withdrawal",
        Cell: customAmountCell,
      },
      {
        Header: "PeriodId",
        accessor: "periodId",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: userEarnings ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["periodId"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;
  return (
    <>
      <div className="row mx-0 px-0 mt-4">
        <div className="row mx-0  col-sm-12 col-md-3 px-0">
          <div className="earnings credit w-100 mr-3">
            <div className="earnings-title mb-1">Total earned</div>
            <div className="mb-4 my-money-section-1-text p-0">
              Your total earnings after applying royalty splits.
            </div>
            <div className="earnings-amount text-grey">
              <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
              {totalReported.toFixed(2)}
            </div>
          </div>
        </div>
        
        <div className="row mx-0  col-sm-12 col-md-3 px-0">
          <div className="earnings credit w-100 mr-3">
            <div className="earnings-title mb-1">Total withdrawals</div>
            <div className="mb-4 my-money-section-1-text p-0">
              The total of your requested payouts.
            </div>
            <div className="earnings-amount text-grey">
              <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
              {totalWithdrawalAmount.toFixed(2)}
            </div>
          </div>
        </div>

        <div className="row mx-0  col-sm-12 col-md-3 px-0">
          <div className="earnings credit w-100 mr-3">
            <div className="earnings-title mb-1">Total pending</div>
            <div className="mb-4 my-money-section-1-text p-0">
              Waiting to receive payment from platforms.
            </div>
            <div className="earnings-amount text-grey">
              <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
              {pendingAmount.toFixed(2)}
            </div>
          </div>
        </div>

        <div className="row mx-0  col-sm-12 col-md-3 px-0">
          <div className="earnings credit w-100 mr-3">
            <div className="earnings-title mb-1">Total collected</div>
            <div className="mb-4 my-money-section-1-text p-0">
              These amounts have been paid out by the platforms, and are now
              available for you to withdraw.
            </div>
            <div className="earnings-amount text-primary mb-4">
              {withdrawalAmount > 0 ? (
                <>
                  <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
                  {Number(withdrawalAmount).toFixed(2)}
                </>
              ) : (
                "€ 0.00"
              )}
            </div>
            <Button
              className={
                "earningsButton" +
                (isPayoutEnabled ? " " : "-inactive ") +
                "my-money-withdraw-btn" +
                (isPayoutEnabled ? "-active" : "-inactive")
              }
              onClick={() => handleOpenPayoutModal()}
            >
              Request payout
            </Button>
          </div>
        </div>

      </div>
      <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-2">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>

        <TableView tableInstance={tableInstance} loading={loading} />
      </main>
    </>
  );
};

export default MyBalance;
