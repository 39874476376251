import React, { useEffect, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import ProfileHeader from "../../artistDashboard/Profile/components/ProfileHeader";

import RequestPayoutModal from "../components/RequestPayoutModal";
import { setUserTransactions } from "../../../redux/actions/moneyActions";
import TransactionHistory from "./components/TransactionHistory";

import { Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../../artistDashboard/TabPanel";

import "./index.scss";
import MyBalance from "./components/MyBalance";
import moment from "moment";

const STRIPE = "stripe";
const PAYPAL = "paypal";

function MyMoney() {
  const dispatch = useDispatch();
  const [totalReported, setTotalReported] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState(0);

  const [pendingAmount, setPendingAmount] = useState(0);
  const [isPayoutEnabled, setIsPayoutEnabled] = useState(false);
  const [payoutModal, setPayoutModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePeriodClick = (periodId) => {
    setSelectedPeriodId(periodId);
    setValue(1);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setLoading(true);
    dispatch(setUserTransactions()).then(() => {
      setLoading(false);
    });
  }, []);

  const earnings = useSelector((state) => state.moneyReducer?.userTransactions);

  function handleOpenPayoutModal() {
    if (withdrawalAmount >= 25) setPayoutModal(true);
  }
  useEffect(() => {
    let reported = 0;
    let withdrawal = 0;

    let totalWithdrawal = 0;

    let pending = 0;

    earnings.forEach((earning) => {
      let exclude = false;
      if (earning.description.includes('Recouped ')) {
          exclude = true;
      }
      // reported += earning.amount;

      if (earning.type === "debit" && !exclude) {
        totalWithdrawal += earning.amount;
      } else {
        reported += earning.amount;
      }
      if (earning.status !== "awaiting_payment") {
        withdrawal += earning.amount;
      }
      if (earning.status === "awaiting_payment") {
        pending += earning.amount;
      }
    });

    setTotalWithdrawalAmount(totalWithdrawal);

    setTotalReported(reported);
    setWithdrawalAmount(withdrawal);
    setPendingAmount(pending);
    setIsPayoutEnabled(withdrawal >= 25);
  }, [earnings]);

  const groupedEarnings = earnings.reduce((acc, curr) => {
    const periodId = curr?.metadata?.period?._id;
    if (!acc[periodId]) {
      acc[periodId] = {};
    }

    let exclude = false;
    if (curr.description.includes('Recouped ')) {
        exclude = true;
    }

    acc[periodId] = {
      periodId,
      period: curr?.metadata?.period?.endDate
        ? moment(curr?.metadata?.period?.endDate).format("YYYY-MM")
        : "",
      totalReported: 
        curr.type=='credit' || exclude ?
          (acc[periodId]?.totalReported ?? 0) + curr.amount 
          : (acc[periodId]?.totalReported ?? 0) ,
      totalCollected:
        (curr.type=='credit' || exclude ) && curr.status !== "awaiting_payment"
          ? (acc[periodId]?.totalCollected ?? 0) + curr.amount
          : (acc[periodId]?.totalCollected ?? 0) ,
      
      totalPending:
        (curr.status === "awaiting_payment" || exclude && curr.status === "awaiting_payment")
          ? (acc[periodId]?.totalPending ?? 0) + curr.amount
          : (acc[periodId]?.totalPending ?? 0),

      withdrawal: (curr.type=='debit' && !exclude)
          ? (acc[periodId]?.withdrawal ?? 0) + curr.amount
          : (acc[periodId]?.withdrawal ?? 0),
    };

    return acc;
  }, {});

  const groupedEarningsArray = Object.values(groupedEarnings).sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateB - dateA;
  });

  return (
    <main className="my-earnings">
      <ProfileHeader label="My earnings" />
      <RequestPayoutModal
        modalClassName={payoutModal ? "my-money-payoutModal" : "d-none"}
        overlayClassName={payoutModal ? "" : "d-none"}
        setModal={setPayoutModal}
        userBalance={withdrawalAmount}
      />
      <section className="my-money-section">
        <div className="my-money-section-1-text">
          As soon as we get your cash from streaming services, it will be
          displayed on the{" "}
          <Link to="/artistDashboard/revenue">Revenue page</Link>. Usually, your
          earnings are reported 3 months after the track was first sold or
          streamed. You can withdraw your account balance as soon as it reaches
          the treshold of € 25.00.
        </div>
      </section>
      <div className="my-money-section ">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="MY BALANCE" {...a11yProps(0)} />
          <Tab label="TRANSACTIONS" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <MyBalance
            userEarnings={groupedEarningsArray}

            withdrawalAmount={withdrawalAmount}
            totalWithdrawalAmount={totalWithdrawalAmount}
            isPayoutEnabled={isPayoutEnabled}
            handleOpenPayoutModal={handleOpenPayoutModal}
            totalReported={totalReported}
            pendingAmount={pendingAmount}
            handlePeriodClick={handlePeriodClick}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TransactionHistory
            selectedPeriodId={selectedPeriodId}
            setSelectedPeriodId={setSelectedPeriodId}
          />
        </TabPanel>
      </div>
    </main>
  );
}

export default MyMoney;
