import React, { useState } from "react";
import { useQuery } from "react-query";
import X from "../../../../../components/distribution/assets/X.svg";
import copyIcon from "../../../../../assets/icons/copy-btn-icon.svg";
import { generate2FACode, setup2FACode } from "../../../../../services/auth";
import ButtonComponent from "../../../../form/ButtonComponent";
import InputComponent from "../../../../form/InputComponent";
import ValidationTextBox from "../../../../form/ValidationTextBox";
import { Spinner } from "../../../../../screens/Airplay/Airplay";
import ViewBackupCodesModal from "./ViewBackupCodesModal";

const AuthAppModal = ({ onCloseRequest, setToast, refetch2FAState }) => {
  const [page, setPage] = useState(0);
  const [twoFactorCodeError, set2FactorCodeError] = useState("");
  const [twoFactorCode, set2FACode] = useState("");
  const [touched, setTouched] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [backupCodes, setBackupCodes] = useState({
    codes: [],
    createdAt: null,
  });
  const [verifying, setVerifying] = useState(false);

  const queryConfig = {
    refetchOnWindowFocus: false,
    cacheTime: 3_600_000, //milliseconds
  };

  const { data: { data: twoFASetup } = {}, loading: twoFactorCodeLoading } =
    useQuery("2fa-setup", generate2FACode, queryConfig);

  const handle2FACodeChange = (value) => {
    set2FACode(value);

    if (touched) validate();
  };

  const handle2FACodeBlur = () => {
    validate();
    setTouched(true);
  };

  const handle2FAEnable = async (e) => {
    e.preventDefault();
    const isError = validate();

    if (isError) return;
    set2FactorCodeError("");

    try {
      setVerifying(true);
      const response = await setup2FACode(twoFactorCode);

      if (response.data.success) {
        refetch2FAState();

        setToast({
          type: "success",
          open: true,
          message: "2FA has been setup successfully",
        });

        set2FACode("");

        if (response.data.backup?.codes?.length) {
          setBackupCodes(response.data.backup);
          setPage(2);
        } else {
          onCloseRequest();
        }
      }
      setVerifying(false);
    } catch (error) {
      setToast({
        type: "danger",
        open: true,
        message:
          error?.response?.data?.message ||
          "An unexpected error occured. Please try again",
      });
      setVerifying(false);
    }
  };

  const validate = () => {
    let isError = false;
    if (!Number(twoFactorCode)) {
      isError = true;
      set2FactorCodeError("Invalid 2FA code");
    } else if (twoFactorCode.length !== 6) {
      isError = true;
      set2FactorCodeError("Invalid 2FA code. Max length is 6");
    } else {
      isError = false;
      set2FactorCodeError("");
    }
    return isError;
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(twoFASetup?.secret)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="playtreksModal-overlay">
        <div className="TwoFAModal">
          <img
            src={X}
            width={16}
            height={16}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={onCloseRequest}
          />

          {page === 0 && (
            <>
              <div className="mt-4">
                <div>Get codes from the Authenticator app</div>
              </div>
              <div className="mt-4 infoText text-muted">
                Instead of waiting for text messages, get verification codes for
                free from the Authenticator app. It works even if your phone is
                offline.
              </div>

              {/* Line break */}
              <div
                className="LineBreak w-100 mt-3 mb-3 mt-0"
                style={{ top: 0 }}
              ></div>

              <div className="mb-3">
                <div>Set up Authenticator</div>
              </div>

              <ul className="infoText text-muted pl-3">
                <li>Get the Authenticator App of your choice.</li>
                <li>
                  In the App select{" "}
                  <span className="text-white">Set up account.</span>
                </li>
                <li>
                  Choose <span className="text-white"> Scan a barcode.</span>
                </li>
              </ul>

              {/* SCAN QR CODE */}
              {twoFactorCodeLoading ? (
                <div className="row justify-content-center align-items-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className="text-center">
                    <img
                      src={`https://quickchart.io/chart?chs=175x175&chld=Q|0&cht=qr&chl=${
                        twoFASetup?.otpurl || ""
                      }`}
                      alt="2fa qr code"
                    />
                    {/* 2fA secret */}
                    <div>
                      <p className="fs-12 col text-muted text-center mt-2">
                        Can't scan it? Copy secret key below
                      </p>

                      <div className="row align-items-center mx-0">
                        <div className="text-truncate w-75 fs-14 mt-1">
                          {twoFASetup?.secret}{" "}
                        </div>
                        <span className="ml-2">
                          <button
                            className="copy-btn"
                            onClick={handleCopyClick}
                          >
                            <span className="cnb-button__content">
                              {isCopied ? (
                                <span
                                  style={{
                                    color: "#90FF00",
                                  }}
                                  className="fs-16"
                                >
                                  ✓ <span className="fs-12">Copied!</span>
                                </span>
                              ) : (
                                <img
                                  src={copyIcon}
                                  alt="copy"
                                  style={{ height: "20px" }}
                                />
                              )}
                            </span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* 2FA code input */}
                  <div className="row justify-content-end ml-0 mt-4">
                    <ButtonComponent
                      onClick={onCloseRequest}
                      className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      className="playtreksButton w-100 h-100 "
                      onClick={() => setPage((currPage) => currPage + 1)}
                    >
                      Next
                    </ButtonComponent>
                  </div>
                </>
              )}
            </>
          )}

          {page === 1 && (
            <form onSubmit={handle2FAEnable}>
              <div className="mt-4">
                <div>Set up Authenticator</div>
              </div>
              <div className="mt-4 infoText text-muted">
                Enter the 6-digit code you see in the app.
              </div>

              <div className="mt-4">
                <div className="inputUpperComment iuc1a  d-none">2FA code</div>
                <InputComponent
                  addon={false}
                  alt="2fa code"
                  value={twoFactorCode}
                  commentField=".iuc1a "
                  paddingTopOnInput={true}
                  placeholder="2FA code"
                  inputGroupTextClassName="inputGroupText"
                  inputClassName="TwoFAInput"
                  inputGroupClassName="nrInputGroup nrInputGroup-title"
                  changeCallback={handle2FACodeChange}
                  inputType="number"
                  databaseField="totp"
                  maxlength={6}
                  inputBlurCallback={handle2FACodeBlur}
                />
                <ValidationTextBox
                  className="loginValidationBox position-static ml-0"
                  display={twoFactorCodeError?.length}
                >
                  {twoFactorCodeError}
                </ValidationTextBox>
              </div>
              <div className="row justify-content-start ml-0 mt-4">
                <ButtonComponent
                  onClick={() => setPage((next) => next - 1)}
                  className="playtreksButton w-100 h-100 bg-transparent textLink"
                >
                  Back
                </ButtonComponent>

                <ButtonComponent
                  className="playtreksButton w-100 h-100"
                  disabled={!twoFactorCode}
                  onClick={handle2FAEnable}
                  type="submit"
                >
                  {verifying ? (
                    <>
                      Verifying...
                      <span className="ml-2">
                        <Spinner />
                      </span>
                    </>
                  ) : (
                    "Verify"
                  )}
                </ButtonComponent>
              </div>
            </form>
          )}

          {page === 2 && <ViewBackupCodesModal backupCodes={backupCodes} />}
        </div>
      </div>
    </>
  );
};

export default AuthAppModal;
